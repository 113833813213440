@import '../node_modules/modern-normalize/modern-normalize.css';

:root {
  --background-color: #fff;
  --primary-color: #00083d;
  --call-to-action-color: #0062ff;
  --secondary-color: #646464;
}

[data-theme='dark'] {
}

:local {
  .app {
    height: 100vh;
    background-color: var(--background-color);
  }
}
