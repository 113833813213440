:local {
  .button {
    // min-width: 250px;
    height: 50px;
    padding: 0 20px;
    border-radius: 60px;
    border: none;
    background: none;
    background-size: 200% auto;
    transition: 0.2s background-position;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    color: var(--call-to-action-color);

    &:hover {
      background-position: right center;
    }
  }

  .callToAction {
    color: #fff;
    background-image: linear-gradient(
      to right,
      #5a98f4 0%,
      #276dd6 51%,
      #5a98f4 100%
    );
    box-shadow: 0px 4px 4px 0 rgba(0, 0, 0, 0.25);
  }
}
