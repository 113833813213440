:local {
  .navbar {
    height: 64px;
    display: flex;
    justify-content: center;
    background-color: var(--background-color);
    backdrop-filter: blur(10px);

    // Drop shadow only on the bottom of the element
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15);
    clip-path: inset(0 0 -10px 0);

    .innerContainer {
      width: 100%;
      height: 100%;
      padding: 0 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .left,
      .right {
        height: 100%;
        display: flex;
        align-items: center;
      }

      .left {
        align-self: flex-start;
      }

      .right {
        align-self: flex-end;

        .hamburger {
          cursor: pointer;
          width: 35px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  @media screen and (min-width: 1100px) {
    .navbar {
      .innerContainer {
        max-width: 1100px;
      }
    }
  }
}
