:local {
  .backgroundWrapper {
    background: linear-gradient(#fff, #efefef);
  }

  .wrapper {
    margin: 0 auto;
    padding: 75px 0;
  }

  .description {
    padding-left: 20%;
    padding-right: 20%;
    text-align: center;
    font-size: 24px;
    color: var(--secondary-color);

    h2 {
      font-size: 36px;
      color: var(--primary-color);
    }
  }

  .cards {
    columns: 2;
    column-gap: 3%;
    padding: 0 20px 0 20px;
    margin-top: 50px;

    .card {
      width: 100%;
      margin-bottom: 50px;
      padding: 40px 0 15px 35px;
      overflow: hidden;
      cursor: pointer;
      transition: transform 0.2s;

      .title {
        display: block;
        font-size: 24px;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 7px;
      }

      .subtitle {
        font-size: 32px;
        font-weight: 700;
        color: #fff;
      }
    }

    .card:hover {
      transform: scale(1.05);
    }

    .card:nth-child(2n + 1) {
      margin-right: 4%;
    }

    .manzama {
      background: linear-gradient(#777c98, #5f637d);

      .title {
        color: rgba(177, 185, 227, 0.7);
      }

      .img {
        height: 500px;
        display: block;
        margin-left: auto;
        margin-top: 40px;
      }
    }

    .cloudera {
      background: linear-gradient(#ed882a, #d4741b);
      padding-bottom: 0px;

      .title {
        color: rgba(156, 86, 21, 0.7);
      }

      .img {
        height: 300px;
        display: block;
        margin-left: auto;
        margin-top: 40px;
        margin-bottom: -25px;
        padding-left: 25px;
      }
    }
  }

  @media screen and (min-width: 1100px) {
    .wrapper {
      width: 1100px;
    }
  }

  @media screen and (max-width: 850px) {
    .cards {
      columns: 1;

      .card {
        width: 100%;
        margin-right: 0;
      }
    }
  }

  @media screen and (max-width: 574px) {
    .wrapper {
      padding-left: 20px;
      padding-right: 20px;
    }

    .description {
      padding: 0;
    }
  }
}
